<template>
  <div class="main-page">
    <Header msg="入金订单"  ></Header>

    <BScroll  class="content" @pullingUp="fnpullingUp" ref="scrollRef">
        <van-empty description="暂无记录" v-if="data.empty"/>

        <div class="ulist">

            <div class="uitem" v-for="item in data.list " :key="item.id">

                <div class="title  d-flex align-center justify-between">

                    <div class="left">

                        <span :class="item.state==1?'state':'state2'">
                            
                            {{ item.state ==1?"产出中":"已出局" }}
                        </span>

                        <span>
                            {{ getBit(item.produce_u) }} USDT / {{ item.residues}}  USDT
                        </span>
                    </div>

                    <div class="right" @click="()=>{
                            $router.push('./lrls?oid='+ item.id)
                        }">
                        <span>产币记录</span>
                        <van-icon name="arrow" color="#FFFFFF" />
                    </div>

                </div>


                <div class="uinfo d-flex align-center justify-between">

                    <div>
                        <div class="ut"><span>产币数量(LR)</span></div>
                        <div class="uss"><span>{{item.produce_g}}</span></div>
                    </div>

                    <div>
                        <div class="ut"><span>金额(USDT)</span></div>
                        <div class="uss"><span>{{ item.amount }}</span></div>
                    </div>

                    <div class="endser">
                        <div class="ut"><span>时间</span></div>
                        <div class="uss"><span>{{ item.time }}</span></div>
                    </div>

                </div>
            </div>

        </div>
    </BScroll>
   </div>
</template>

<script  setup>
import { reactive, toRefs, computed, watch, ref, onMounted,nextTick } from "vue";
import Header from "@/components/Header";
import BScroll from "@/components/BScroll";
import { deposit } from "@/common/api";
import { useStore } from "vuex";
import {getBit} from '@/utils/string';

import { Toast } from 'vant';
const scrollRef = ref(null);
const $store = useStore();
const data = reactive({
  list: [],
  empty: false,
  page:0,
  pages:0
});
const account = computed(() => {
  return $store.state.account;
});
const fninit = () => {
  const params = {
    address: account.value,
    page: data.page,

  };
  deposit(params).then((res) => {
    if (res.code == 200) {
      data.pages = res.data.pages;
      data.list = [...data.list, ...res.data.data];
      if (data.list.length) {
        data.empty = false;
      } else {
        data.empty = true;
      }
      nextTick(() => {
        scrollRef.value.refresh();
      });
    } else {
      data.empty = true;
    }
  });
};

const fnpullingUp = () => {
  data.page = data.page + 1;

  if (data.page >= data.pages) {
    return;
  }
  fninit();
};

watch(
  account,
  (newVal) => {
    newVal && fninit();
  },
  { immediate: true, deep: true }
);
</script>
<style scoped lang='scss'>

    .ulist{
        padding:  0 .32rem;
        padding-bottom: .6rem;
        .uitem{
            height: 2rem;
            background-color: #182743;
            border-radius: .14rem;
            padding: 0 .3rem;
            padding-top: .2rem;
            margin-top: .3rem;
            font-size: .24rem;

            .title{
                height: .6rem;
                font-size: .22rem;
                color: #A1AEC5;
                .state{
                    color: #006CFF;
                    font-size: .26rem;
                    margin-right: .12rem;
                }
                .state2{
                    color: #FF828C;
                    font-size: .26rem;
                    margin-right: .12rem;
                }
                .right{
                    color: #FFFFFF;
                }
            }
            .uinfo{
                height: 1.1rem;
               .ut{
                    font-size: .22rem;color: #A1AEC5;
               }
               .uss{
                font-size: .26rem;
                line-height: .6rem;
               }

               .endser{
                // background-color: pink;
                text-align: right;
               }

            }
        }
    }
</style>